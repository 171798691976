import ReactDOM from "react-dom";
import { memo, ReactNode, useEffect, useRef } from "react";
import classnames from "classnames";

import Close from "assets/images/close.svg";
import CloseLight from "assets/images/close-light.svg";

interface IProps {
  headerChildren?: React.ReactNode | React.ReactNodeArray;
  children: React.ReactNode | React.ReactNodeArray;
  portalNode?: HTMLElement;
  className?: string;
  isShowing?: boolean;
  showTitle?: boolean;
  showHeader?: boolean;
  showSearchSidebar?: boolean;
  onHide?: () => void;
  title?: ReactNode;
  closeLight?: boolean;
  classNames?: {
    popup?: string;
    title?: string;
    popupContainer?: string;
    popupInner?: string;
    modalHeader?: string;
    backgroundImage?: string;
    closeButtonContainer?: string;
    closeButton?: string;
    borderLine?: string;
    childrenContainer?: string;
    searchChildrenContainer?: string;
    headerChildrenStyle?: string;
  };
  customMaxWidth?: boolean;
  isSaving?: boolean;
}
function Modal({
  isShowing = false,
  showHeader = true,
  showSearchSidebar = false,
  onHide,
  children,
  className,
  closeLight,
  showTitle = true,
  classNames,
  headerChildren,
  portalNode = document.body,
  title = "",
  customMaxWidth,
  isSaving = false,
}: IProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onHide?.();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const isInPopup =
        target.closest(".confirmation-modal") ||
        target.closest(".alert-modal") ||
        target.closest(".filter-modal");

      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !isInPopup
      ) {
        onHide?.();
      }
    };

    if (isShowing) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShowing, onHide]);

  return (
    <>
      {isShowing &&
        ReactDOM.createPortal(
          <div
            className={classnames(
              "z-[99] bg-[#00000075] min-w-[375px] !top-[0px] fixed left-0 w-full h-full outline-none flex flex-col items-center overflow-y-hidden",
              className
            )}
            tabIndex={-1}
            role="dialog"
          >
            <div
              className={classnames(
                classNames?.popupContainer,
                "!overflow-y-auto h-full px-2 px-2 md:py-10 w-full flex items-center justify-center",

                { "hover:cursor-progress": isSaving }
              )}
            >
              <div
                ref={modalRef}
                className={classnames(
                  classNames?.popup,
                  "flex flex-col w-full bg-primary opacity-100 z-40 rounded pb-4",
                  { "max-w-2xl": !customMaxWidth },
                  { "pointer-events-none": isSaving }
                )}
              >
                {showHeader && (
                  <>
                    <div
                      className={classnames(
                        "flex w-full relative  px-4 text-xl",
                        classNames?.modalHeader
                      )}
                    >
                      {showTitle && (
                        <div
                          className={classnames(
                            classNames?.title,
                            "text-primary"
                          )}
                        >
                          {title || " "}
                        </div>
                      )}

                      <div
                        className={classnames(
                          classNames?.closeButtonContainer,
                          " "
                        )}
                      >
                        {onHide && (
                          <button
                            type="button"
                            className={classnames(classNames?.closeButton, "")}
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onHide}
                          >
                            <img
                              alt="Close"
                              src={closeLight ? CloseLight : Close}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                    <span
                      className={classnames(
                        classNames?.borderLine,
                        "border-primary border-t mt-2 px-8 w-full"
                      )}
                    ></span>
                    {headerChildren && (
                      <div
                        className={classnames(classNames?.headerChildrenStyle)}
                      >
                        {headerChildren}
                      </div>
                    )}
                  </>
                )}

                <div
                  className={classnames(
                    classNames?.popupInner,

                    {
                      "flex flex-row mt-0 px-0": showSearchSidebar,
                      "px-4 mt-2 w-full bg-primary": !showSearchSidebar,
                    }
                  )}
                >
                  <div className={classnames(classNames?.childrenContainer)}>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>,
          portalNode
        )}
    </>
  );
}

export default memo(Modal);
