import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
export interface ConfirmationModalConfig {
  title: string;
  description: React.ReactNode | string;
  buttonText: string;
  buttonTextCancel?: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onApprove: (params?: { [x: string]: any }) => void;
  params?: object;
  hideCancelButton?: boolean;
}

export function ConfirmationModal({
  isOpen,
  title,
  description,
  buttonText,
  buttonTextCancel = "Cancel",
  onApprove,
  setOpen,
  hideCancelButton = false,
}: ConfirmationModalConfig) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0 confirmation-modal">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-tablePrimary p-6 text-left shadow-xl transition-all sm:my-8 w-fit max-w-md">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold text-primary text-center"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2 text-sm text-secondary text-center">
                  {typeof description === "string" ? (
                    <p>{description}</p>
                  ) : (
                    description
                  )}
                </div>
                <div className="mt-6 flex justify-center gap-3">
                  {!hideCancelButton && (
                    <button
                      ref={cancelButtonRef}
                      onClick={() => setOpen(false)}
                      className="w-[50%] rounded border border-primaryBlue bg-white px-4 py-2 text-sm font-medium text-primaryBlue hover:opacity-80"
                    >
                      {buttonTextCancel}
                    </button>
                  )}
                  <button
                    onClick={() => onApprove()}
                    className="w-[50%] rounded bg-primaryBlue px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                  >
                    {buttonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmationModal;
